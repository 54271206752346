import React from "react";
import { kebabCase } from "lodash";
import { Link } from "gatsby";

import { format } from "date-fns";
import styled from "styled-components";
import {
  sizes,
  colorAliases,
  fontSizes,
  borderRadius,
  MOBILEBREAKPOINT,
} from "../style/design-system";
import OuterContainer from "../components/OuterContainer";
import NewsletterBox from "../components/NewsletterBox";

const SectionInnerContainer = styled.div`
  width: 100%;
  max-width: ${sizes.siteWidth};
  margin: 0 auto;
  padding: ${sizes["lg"]} ${sizes.lg} ${sizes.lg} ${sizes.lg};
`;

const SideBySide = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    display: block;
  }
`;

const Half = styled.div`
  width: calc(50% - ${sizes.md});
  @media (max-width: ${MOBILEBREAKPOINT}) {
    width: 100%;
  }
`;

const Image = styled.img`
  display: block;
  width: 100%;
  margin: 0;
  border-radius: ${borderRadius.md};
`;

const TopImage = styled(Image)`
  @media (max-width: ${MOBILEBREAKPOINT}) {
    margin-bottom: ${sizes.md};
  }
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Tag = styled(Link)`
  text-transform: uppercase;
  color: ${colorAliases.red};
  margin-right: ${sizes.md};
  text-decoration: none;
  letter-spacing: 2px;
  font-size: ${fontSizes.sm};

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: ${MOBILEBREAKPOINT}) {
    margin-bottom: ${sizes.xs};
  }
`;

const InvisiLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: ${colorAliases.textColor};

  &:hover {
    text-decoration: underline;
  }
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: ${fontSizes["3xl"]};
  margin: ${sizes["sm"]} 0 ${sizes.sm} 0;
`;

const FormattedDate = styled.h5`
  color: ${colorAliases.grayText};
  font-weight: 300;
`;

const TwoColumns = styled.div`
  display: flex;
`;

const Left = styled.div`
  width: 100%;
  margin-right: ${sizes["xl"]};

  @media (max-width: ${MOBILEBREAKPOINT}) {
    margin-right: 0;
  }
`;
const Right = styled.div`
  width: 30%;
  max-width: 300px;
  @media (max-width: ${MOBILEBREAKPOINT}) {
    display: none;
  }
`;

const PostsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: ${sizes.xl};
`;

const PostCard = styled.div`
  width: 100%;
`;

const PostImage = styled(Image)`
  margin-bottom: ${sizes.md};
`;

const PostTag = styled(Tag)`
  font-size: ${fontSizes.xs};
  margin-bottom: ${sizes.xs};
`;

const PostTitle = styled.h2`
  font-weight: 700;
  font-size: ${fontSizes["2xl"]};
  margin: 0 0 ${sizes.xs} 0;
`;

const PostDate = styled(FormattedDate)`
  margin: 0;
`;

const BlogRoll = ({ data, locale }) => {
  const { edges: posts } = data.allMarkdownRemark;
  const firstPost = posts[0].node;
  const {
    featuredimage,
    tags,
    title,
    date,
    description,
  } = firstPost.frontmatter;

  return (
    <OuterContainer>
      <SectionInnerContainer>
        <SideBySide>
          <Half padding="0 16px 0 0 0">
            <TopImage src={`/img/${featuredimage.relativePath}`}></TopImage>
          </Half>
          <Half style={{ display: "flex", alignItems: "center" }}>
            <div>
              <TagsContainer>
                {tags &&
                  tags.length &&
                  tags.map((tag) => (
                    <Tag to={`/tags/${kebabCase(tag)}/`} key={tag}>
                      {tag}
                    </Tag>
                  ))}
              </TagsContainer>
              <InvisiLink to={firstPost.fields.slug}>
                <Title>{title}</Title>
              </InvisiLink>
              <p>{description}</p>
              <FormattedDate>
                {format(new Date(date), `PP`, { locale })}
              </FormattedDate>
            </div>
          </Half>
        </SideBySide>
      </SectionInnerContainer>

      <SectionInnerContainer style={{ marginBottom: sizes["2xl"] }}>
        <TwoColumns>
          <Left>
            <PostsContainer>
              {posts.slice(1).map(({ node: post }) => {
                const {
                  featuredimage,
                  tags,
                  title,
                  date,
                  description,
                } = post.frontmatter;
                return (
                  <PostCard key={title}>
                    <PostImage
                      src={`/img/${featuredimage.relativePath}`}
                    ></PostImage>
                    <TagsContainer fontSize={fontSizes.xs}>
                      {tags &&
                        tags.length &&
                        tags.map((tag) => (
                          <PostTag to={`/tags/${kebabCase(tag)}/`} key={tag}>
                            {tag}
                          </PostTag>
                        ))}
                    </TagsContainer>
                    <InvisiLink to={post.fields.slug}>
                      <PostTitle>{title}</PostTitle>
                    </InvisiLink>
                    <p>{description}</p>
                    <PostDate>
                      {format(new Date(date), `PP`, { locale })}
                    </PostDate>
                  </PostCard>
                );
              })}
            </PostsContainer>
          </Left>
          {/* <Right>
            <NewsletterBox></NewsletterBox>
          </Right> */}
        </TwoColumns>
      </SectionInnerContainer>
    </OuterContainer>
  );
};

export default BlogRoll;
