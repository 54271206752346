import React from "react";
import { Helmet } from "react-helmet";
import useSiteMetadata from "../../components/SiteMetadata";
import Layout from "../../components/Layout";
import BlogRollNo from "../../components/BlogRollNo";

const BlogIndexPage = () => {
  const { title } = useSiteMetadata();
  return (
    <Layout language="no">
      <Helmet>
        <title>{`Artikler | ${title}`}</title>
      </Helmet>
      <BlogRollNo />
    </Layout>
  );
};

export default BlogIndexPage;
